const links = [
  {
    link: "/",
    text: "About",
  },
  {
    link: "/games",
    text: "Games",
  },
  // {
  //   link: "/blog",
  //   text: "Blog",
  // },
]


export default links
